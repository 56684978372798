import { ChangeEvent, useRef, useState } from "react";
import { Box, Button, IconButton, Link, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import { getFormattedDate, getFormattedDateTime } from "utils";
import { Project } from "repositories/owner/Project";
import { FinalInspectionUpdateDialog } from "./FinalInspectionUpdateDialog";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { ownerStorage } from "lib/firebase";
import { UpdateProjectByFinalInspectionAgreement } from "@shared/types/functionParams";
import { updateProjectByFinalInspectionAgreement } from "features/projectDetail/api/updateProjectByFinalInspectionAgreement";
import { useSnackbar } from "context/snackbarContext";
import { useBackdrop } from "context/backdropContext";
import { downloadFile, fetchBlobURL } from "../../useCases/storage/contractFile";

interface FinalInspectionContentProps {
  project: Project;
}
export const FinalInspectionContent = ({ project }: FinalInspectionContentProps): JSX.Element => {
  const [isFinalInspectionUpdateDialogOpen, setIsFinalInspectionUpdateDialogOpen] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { setBackdrop } = useBackdrop();
  const { showSnackbar } = useSnackbar();

  const uploadFile = {
    /**アップロードボタンクリック → inputクリックしたことにする */
    handleFileSelectButtonClick: (): void => {
      fileInputRef.current?.click();
    },
    /**ファイル選択 → storageに保存+firestoreに保存 */
    handleFileChange: async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
      const file = event?.target?.files?.[0];
      if (!file) {
        return;
      }

      await uploadFile.saveFile(file);
    },
    /**storageに保存 + firestoreに保存 */
    saveFile: async (file: File): Promise<void> => {
      try {
        setBackdrop(true);
        const dirPath = `companies/${project.companyId}/projects/${project.id!}/finalInspectionAgreement`;

        const storageRef = ref(ownerStorage, `${dirPath}/${file.name}`);
        await uploadBytes(storageRef, file);

        const downloadURL = await getDownloadURL(storageRef);

        const params: UpdateProjectByFinalInspectionAgreement.RequestParams = {
          name: file.name,
          url: downloadURL,
          companyId: project.companyId,
          projectId: project.id!,
        };
        await updateProjectByFinalInspectionAgreement(params);

        showSnackbar("合意書をアップロードしました。", "success");
      } catch (e) {
        showSnackbar("合意書のアップロードに失敗しました。", "error");
      } finally {
        setBackdrop(false);
      }
    },
  };

  const handleDownloadAgreement = async (name: string, url: string): Promise<void> => {
    const blobURL = await fetchBlobURL(url);
    downloadFile(blobURL, name);
  };

  return (
    <>
      <Box className="min-w-[50%]">
        <Box className="flex items-center">
          <Box>依頼する</Box>
          <IconButton onClick={() => setIsFinalInspectionUpdateDialogOpen(true)}>
            <EditIcon />
          </IconButton>
        </Box>

        <Box className="mt-2">
          <Box>{`立会日時: ${project.finalInspection.date ? getFormattedDateTime(project.finalInspection.date) : "未設定"}`}</Box>
          <Box>{`契約終了日: ${project.finalInspection.leaseEndDate ? getFormattedDate(project.finalInspection.leaseEndDate) : ""}`}</Box>
          <Box>{`入居者氏名: ${project.finalInspection.residentName || ""}`}</Box>
          <Box>{`電話番号: ${project.finalInspection.phoneNumber || ""}`}</Box>
          {project.finalInspection.agreement ? (
            <Tooltip title="ダウンロード" placement="right">
              <Box className="flex items-center">
                <span
                  className="text-remodela-green cursor-pointer underline decoration-[#1976d366] hover:decoration-remodela-green"
                  onClick={() => handleDownloadAgreement(project.finalInspection.agreement!.name, project.finalInspection.agreement!.url)}
                >
                  {project.finalInspection.agreement.name}
                </span>
                <div>(合意書)</div>
              </Box>
            </Tooltip>
          ) : (
            <>
              <Button variant="contained" className="font-bold text-xs rounded-lg bg-remodela-green" onClick={uploadFile.handleFileSelectButtonClick}>
                合意書アップロード
              </Button>
              <input type="file" ref={fileInputRef} onChange={uploadFile.handleFileChange} className="hidden" />
            </>
          )}
        </Box>

        {project.witnessDocuments?.map((witnessDocument, index) => {
          return (
            <Box key={index} className="mb-1">
              <Tooltip title="ダウンロード" placement="right">
                <Link href={witnessDocument.url} target="_blank" className="text-remodela-green" variant="body2">
                  {witnessDocument.name}
                </Link>
              </Tooltip>
            </Box>
          );
        })}
      </Box>

      <FinalInspectionUpdateDialog
        companyId={project.companyId}
        projectId={project.id!}
        finalInspection={project.finalInspection}
        open={isFinalInspectionUpdateDialogOpen}
        onClose={() => setIsFinalInspectionUpdateDialogOpen(false)}
      />
    </>
  );
};
