import { format } from "date-fns";
import { ja } from "date-fns/locale";
import { FireTimestamp } from "./firebaseUtil";

export function getFormattedDate(timestamp: FireTimestamp | undefined | null): string {
  if (!timestamp) {
    return "";
  }

  return format(timestamp.toDate(), "yyyy-MM-dd");
}

export function getFormattedDateTime(timestamp: FireTimestamp | undefined | null): string {
  if (!timestamp) {
    return "";
  }

  return format(timestamp.toDate(), "yyyy-MM-dd HH:mm:ss");
}

export function getFormattedDateAtTime(timestamp: FireTimestamp | undefined | null): string {
  if (!timestamp) {
    return "";
  }

  return format(timestamp.toDate(), "HH:mm");
}

export function getFormattedDateWithDay(timestamp: FireTimestamp | undefined | null): string {
  if (!timestamp) {
    return "";
  }

  return format(timestamp.toDate(), "yyyy/MM/dd (EEE)", { locale: ja });
}

/**
 * 入力された文字列から全角の英数字・記号を半角に変換し、数字、小数点、およびマイナス記号のみを含む文字列を返します。
 *
 * 【例】
 * 1. `"１２３．４５６"` → `"123.456"`
 * 2. `"ＡＢＣ１２３。"` → `"123."`
 * 3. `"テスト１２３、４５６"` → `"123456"`
 * 4. `"ー１２３．４５６"` → `"-123.456"`
 * 5. `"-１２３．４５６"` → `"-123.456"`
 *
 * @param val - 変換対象の文字列
 * @returns 変換後の半角数字、小数点、マイナス記号のみからなる文字列
 */
export function toHalfWidthNumbersOnly(val: string): string {
  // 全角英数字・記号を半角に変換
  let halfWidthStr = val.replace(/[\uFF01-\uFF5E]/g, (ch: string) => String.fromCharCode(ch.charCodeAt(0) - 0xfee0));
  // 全角「ー」を半角「-」に変換
  halfWidthStr = halfWidthStr.replace(/ー/g, "-");
  // 「。」を半角ピリオドに変換
  halfWidthStr = halfWidthStr.replace(/。/g, ".");
  // 数字、小数点、マイナス記号以外の文字を削除
  return halfWidthStr.replace(/[^-\d.]/g, "");
}
