import { useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ja } from "date-fns/locale";
import { useStore } from "@nanostores/react";

import { Project } from "@shared/types/entities/owner/Project";
import { updateProjectByFinalInspection } from "../../api/updateProjectByFinalInspection";
import { BizConstractorStore } from "store/nanostores/contractorInfo";
import { useBackdrop } from "context/backdropContext";
import { useSnackbar } from "context/snackbarContext";

interface DialogProps {
  companyId: string;
  projectId: string;
  finalInspection: Project["finalInspection"];
  open: boolean;
  onClose: () => void;
}

export const FinalInspectionUpdateDialog = (props: DialogProps): JSX.Element => {
  const { companyId, projectId, finalInspection, open, onClose } = props;

  const [date, setDate] = useState<Date | undefined>(finalInspection.date?.toDate());
  const [leaseEndDate, setLeaseEndDate] = useState<Date | undefined>(finalInspection.leaseEndDate?.toDate());
  const [residentName, setResidentName] = useState<string>(finalInspection.residentName);
  const [phoneNumber, setPhoneNumber] = useState<string>(finalInspection.phoneNumber);

  const { setBackdrop } = useBackdrop();
  const { showSnackbar } = useSnackbar();
  const { userId } = useStore(BizConstractorStore.IDMap);

  /**立会日時の変更 */
  const handleChangeDate = (newValue: Date | null): void => {
    // 入力値が空の場合
    if (newValue === null) {
      setDate(undefined);
      return;
    }

    // 入力値が完全でない場合(月の指定がないとか)
    if (Number.isNaN(newValue.getTime())) {
      return;
    }

    setDate(newValue);
  };

  /**契約終了日の変更 */
  const handleChangeLeaseEndDate = (newValue: Date | null): void => {
    // 入力値が空の場合
    if (newValue === null) {
      setLeaseEndDate(undefined);
      return;
    }

    // 入力値が完全でない場合(月の指定がない等)
    if (Number.isNaN(newValue.getTime())) {
      return;
    }

    setLeaseEndDate(newValue);
  };

  /**入居者氏名の変更 */
  const handleChangeResident = (residentName: string): void => {
    setResidentName(residentName);
  };

  /**電話番号の変更 */
  const handleChangePhoneNumber = (phoneNumber: string): void => {
    setPhoneNumber(phoneNumber);
  };

  /**登録ボタン押下 */
  const handleClickSave = async (): Promise<void> => {
    try {
      setBackdrop(true);

      await updateProjectByFinalInspection({
        companyId,
        projectId,
        bizUserId: userId,
        finalInspectionDateISOString: date?.toISOString(),
        leaseEndDateISOString: leaseEndDate?.toISOString(),
        residentName,
        phoneNumber,
      });
      showSnackbar("更新しました。", "success");
      onClose();
    } catch (e) {
      showSnackbar("更新に失敗しました。", "error");
    } finally {
      setBackdrop(false);
    }
  };

  return (
    <Dialog fullWidth onClose={onClose} open={open}>
      <DialogTitle>退去立会データ編集</DialogTitle>
      <DialogContent>
        <Box className="flex items-center">
          <p className="min-w-[10rem]">立会日時</p>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
            <DateTimePicker
              defaultValue={date}
              timeSteps={{ minutes: 15 }}
              slotProps={{ textField: { size: "small" } }}
              onChange={handleChangeDate}
            />
          </LocalizationProvider>
        </Box>

        <Box className="flex items-center">
          <p className="min-w-[10rem]">契約終了日</p>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
            <DatePicker defaultValue={leaseEndDate} slotProps={{ textField: { size: "small" } }} onChange={handleChangeLeaseEndDate} />
          </LocalizationProvider>
        </Box>

        <Box className="flex items-center">
          <p className="min-w-[10rem]">入居者氏名</p>
          <TextField value={residentName} size="small" onChange={(e) => handleChangeResident(e.target.value)} />
        </Box>

        <Box className="flex items-center">
          <p className="min-w-[10rem]">電話番号</p>
          <TextField value={phoneNumber} size="small" onChange={(e) => handleChangePhoneNumber(e.target.value)} />
        </Box>
      </DialogContent>
      <DialogActions className="justify-around">
        <Button variant="contained" className="font-bold bg-remodela-green w-40 ml-4" onClick={handleClickSave}>
          登録
        </Button>
      </DialogActions>
    </Dialog>
  );
};
