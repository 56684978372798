import { memo, useState } from "react";
import { Backdrop, BackdropProps, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Link, Tooltip } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ja } from "date-fns/locale";

import { ConstructionTypeCard } from "components/ConstructionTypeCard/ConstructionTypeCard";
import { getFormattedDate, getFormattedDateTime } from "utils";
import { SwapFile } from "@shared/types/entities/owner/Project";
import { Project } from "repositories/owner/Project";
import { useBackdrop } from "context/backdropContext";
import { useSnackbar } from "context/snackbarContext";
import { updateProjectByAccepted } from "../api/updateProjectByAccepted";
import { addBizEstimateComment } from "api/addBizEstimateComment";

export interface UserInfoDialogProps {
  project: Project;
}

export const ProjectBasicInfoDialog = ({ project }: UserInfoDialogProps): JSX.Element => {
  const { showSnackbar } = useSnackbar();
  const { setBackdrop } = useBackdrop();
  const [dateInputDialogOpen, setDateInputDialogOpen] = useState<boolean>(false);

  const handleReject = async (): Promise<void> => {
    setBackdrop(true);

    try {
      await addBizEstimateComment({
        companyId: project.companyId,
        projectId: project.id!,
        comment: "見積作成をお断りしました。",
        pic: "システム自動送信",
      });

      await updateProjectByAccepted({
        companyId: project.companyId,
        projectId: project.id!,
        bizContractorAccepted: false,
        gentyoDate: null,
        bizEstimateScheduledDate: null,
      });

      showSnackbar("見積作成をお断りしました。", "success");
    } catch (e) {
      showSnackbar("更新に失敗しました。", "error");
    } finally {
      setBackdrop(false);
    }
  };

  const handleAccept = async (gentyoDate: Date | null, bizEstimateScheduledDate: Date | null): Promise<void> => {
    try {
      setBackdrop(true);

      await addBizEstimateComment({
        companyId: project.companyId,
        projectId: project.id!,
        comment: "見積作成を引き受けました。",
        pic: "システム自動送信",
      });

      await updateProjectByAccepted({
        companyId: project.companyId,
        projectId: project.id!,
        bizContractorAccepted: true,
        gentyoDate: gentyoDate?.toISOString() || null,
        bizEstimateScheduledDate: bizEstimateScheduledDate?.toISOString() || null,
      });

      showSnackbar("見積作成を引き受けました。", "success");
    } catch (e) {
      showSnackbar("更新に失敗しました。", "error");
    } finally {
      setBackdrop(false);
    }
  };

  return (
    <>
      <Dialog
        open
        slots={{
          backdrop: MemoizedBackdrop,
        }}
      >
        <DialogTitle>
          <Box className="text-base font-bold">{project.companyName} 様から下記案件の見積作成を依頼されました。</Box>
        </DialogTitle>

        <DialogContent className="text-sm">
          <Grid container spacing={1.5}>
            <Grid item xs={3}>
              <Box className="font-bold">案件名</Box>
            </Grid>
            <Grid item xs={9}>
              {project.projectName} / {project.roomNo}
            </Grid>
            <Grid item xs={3}>
              <Box className="font-bold">住所</Box>
            </Grid>
            <Grid item xs={9}>
              {`${project.state}${project.address}`}
            </Grid>
            <Grid item xs={3}>
              <Box className="font-bold">工事タイプ</Box>
            </Grid>
            <Grid item xs={9}>
              <ConstructionTypeCard constructionType={project.constructionType!} />
            </Grid>
            <Grid item xs={3}>
              <Box className="font-bold">ご担当者</Box>
            </Grid>
            <Grid item xs={9}>
              {project.pic}
            </Grid>
            <Grid item xs={3}>
              <Box className="font-bold">工事完了希望日</Box>
            </Grid>
            <Grid item xs={9}>
              {getFormattedDate(project.dueDate) || "なし"}
            </Grid>
            <Grid item xs={3}>
              <Box className="font-bold">退去立会</Box>
            </Grid>
            <Grid item xs={9}>
              {project.needFinalInspection ? <FinalInspectionContent project={project} /> : <Box>依頼しない</Box>}
            </Grid>
            <Grid item xs={3}>
              <Box className="font-bold">その他ご要望</Box>
            </Grid>
            <Grid item xs={9}>
              <Box className="whitespace-pre-line">{project.request}</Box>
            </Grid>
            <Grid item xs={3}>
              <Box className="font-bold">関連資料</Box>
            </Grid>
            <Grid item xs={9}>
              <ShareFileContent project={project} />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions className="justify-around">
          <Button
            variant="outlined"
            className="font-bold border border-remodela-error text-remodela-error hover:bg-remodela-error/5"
            onClick={handleReject}
          >
            お断りする
          </Button>
          <Button variant="contained" className="bg-remodela-green font-bold" onClick={() => setDateInputDialogOpen(true)}>
            引き受ける
          </Button>
        </DialogActions>
      </Dialog>

      <DateInputDialog open={dateInputDialogOpen} onClose={() => setDateInputDialogOpen(false)} handleAccept={handleAccept} />
    </>
  );
};

interface FinalInspectionContentProps {
  project: Project;
}
/**退去立会 */
const FinalInspectionContent = ({ project }: FinalInspectionContentProps): JSX.Element => {
  return (
    <>
      <Box>依頼する</Box>
      <Box className="mt-2">
        <Box>{`立会日時 ${project.finalInspection.date ? getFormattedDateTime(project.finalInspection.date) : "未設定"}`}</Box>
        <Box>{`契約終了日 ${project.finalInspection.leaseEndDate ? getFormattedDate(project.finalInspection.leaseEndDate) : ""}`}</Box>
        <Box>{`入居者氏名 ${project.finalInspection.residentName || ""}`}</Box>
        <Box>{`電話番号 ${project.finalInspection.phoneNumber || ""}`}</Box>
      </Box>

      {project.witnessDocuments &&
        project.witnessDocuments.length > 0 &&
        project.witnessDocuments.map((witnessDocument, index) => {
          return (
            <Box key={index} className="mb-1">
              <Tooltip title="ダウンロード" placement="right">
                <Link href={witnessDocument.url} target="_blank" className="text-remodela-green" variant="body2">
                  {witnessDocument.name}
                </Link>
              </Tooltip>
            </Box>
          );
        })}
    </>
  );
};

interface ShareFileContentProps {
  project: Project;
}
/**関連資料 */
const ShareFileContent = ({ project }: ShareFileContentProps): JSX.Element => {
  const swapOwnerFiles = project.swapOwnerFiles;

  const handleDownloadSwapFile = async (swapOwnerFile: SwapFile): Promise<void> => {
    const response = await fetch(swapOwnerFile.url);
    const blob = await response.blob();
    const blobURL = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = blobURL;
    link.download = swapOwnerFile.name;
    link.click();
    link.remove();
  };

  if (!swapOwnerFiles || swapOwnerFiles.length === 0) {
    return <Box>なし</Box>;
  }

  return (
    <>
      {project.swapOwnerFiles?.map((swapOwnerFile, index) => {
        return (
          <Box key={index}>
            <Tooltip title="ダウンロード" placement="right">
              <span
                className="text-remodela-green cursor-pointer underline decoration-[#1976d366] hover:decoration-remodela-green"
                onClick={() => handleDownloadSwapFile(swapOwnerFile)}
              >
                {swapOwnerFile.name}
              </span>
            </Tooltip>
          </Box>
        );
      })}
    </>
  );
};

interface DateInputDialogProps {
  open: boolean;
  onClose: () => void;
  handleAccept: (gentyoDate: Date | null, bizEstimateScheduledDate: Date | null) => void;
}
/**各日付入力ダイアログ */
const DateInputDialog = ({ open, onClose, handleAccept }: DateInputDialogProps): JSX.Element => {
  const [gentyoDate, setGentyoDate] = useState<Date | null>(null);
  const [bizEstimateScheduledDate, setBizEstimateScheduledDate] = useState<Date | null>(null);

  /**現地調査日の変更 */
  const handleChangeGentyoDate = (newValue: Date | null): void => {
    // 入力値が空の場合
    if (newValue === null) {
      setGentyoDate(null);
      return;
    }

    // 入力値が完全でない場合(月の指定がない等)
    if (Number.isNaN(newValue.getTime())) {
      setGentyoDate(null);
      return;
    }

    setGentyoDate(newValue);
  };

  /**見積作成予定日の変更 */
  const handleChangeBizEstimateScheduledDate = (newValue: Date | null): void => {
    // 入力値が空の場合
    if (newValue === null) {
      setBizEstimateScheduledDate(null);
      return;
    }

    // 入力値が完全でない場合(月の指定がない等)
    if (Number.isNaN(newValue.getTime())) {
      setBizEstimateScheduledDate(null);
      return;
    }

    setBizEstimateScheduledDate(newValue);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent className="text-base">
        <span>下記の日付を入力してください。</span>

        <Box className="flex items-center mt-2">
          <Box className="flex justify-center items-center font-bold rounded-md text-white text-xs w-8 h-6 mr-1 bg-mediumGray">任意</Box>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
            <DatePicker label="現地調査日" slotProps={{ textField: { size: "small" } }} onChange={handleChangeGentyoDate} />
          </LocalizationProvider>
        </Box>

        <Box className="flex items-center mt-2">
          <Box className="flex justify-center items-center font-bold rounded-md text-white text-xs w-8 h-6 mr-1 bg-mediumGray">任意</Box>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
            <DatePicker label="見積作成予定日" slotProps={{ textField: { size: "small" } }} onChange={handleChangeBizEstimateScheduledDate} />
          </LocalizationProvider>
        </Box>
      </DialogContent>
      <DialogActions className="justify-around">
        <Button variant="contained" className="bg-remodela-green font-bold" onClick={() => handleAccept(gentyoDate, bizEstimateScheduledDate)}>
          登録
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const MemoizedBackdrop = memo((props: BackdropProps) => <Backdrop {...props} className="fixed inset-0 backdrop-blur-md bg-black/30" />);
