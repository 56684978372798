import { useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { ja } from "date-fns/locale";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useSnackbar } from "context/snackbarContext";
import { useBackdrop } from "context/backdropContext";
import { UpdateProjectByConstructionPeriod } from "@shared/types/functionParams";
import { Project } from "repositories/owner/Project";
import { updateProjectByConstructionPeriod } from "../../api/updateProjectByConstructionPeriod";

interface ConstructionPeriodDialogProps {
  open: boolean;
  onClose: () => void;
  project: Project;
}
/**工事期間入力ダイアログ */
export const ConstructionPeriodDialog = ({ open, onClose, project }: ConstructionPeriodDialogProps): JSX.Element => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const { showSnackbar } = useSnackbar();
  const { setBackdrop } = useBackdrop();

  /**工事開始日の変更 */
  const handleChangeStartDate = (newValue: Date | null): void => {
    // 入力値が空の場合
    if (newValue === null) {
      setStartDate(null);
      return;
    }

    // 入力値が完全でない場合(月の指定がない等)
    if (Number.isNaN(newValue.getTime())) {
      setStartDate(null);
      return;
    }

    setStartDate(newValue);
  };

  /**工事終了日の変更 */
  const handleChangeEndDate = (newValue: Date | null): void => {
    // 入力値が空の場合
    if (newValue === null) {
      setEndDate(null);
      return;
    }

    // 入力値が完全でない場合(月の指定がない等)
    if (Number.isNaN(newValue.getTime())) {
      setEndDate(null);
      return;
    }

    setEndDate(newValue);
  };

  /**登録ボタン押下 */
  const handleClickSave = async (): Promise<void> => {
    if (!startDate || !endDate) {
      onClose();
      return;
    }

    try {
      setBackdrop(true);

      const updateProjectByConstructionPeriodRequestParams: UpdateProjectByConstructionPeriod.RequestParams = {
        constructionPeriod: {
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
        },
        companyId: project.companyId,
        projectId: project.id!,
      };
      await updateProjectByConstructionPeriod(updateProjectByConstructionPeriodRequestParams);

      showSnackbar("工事期間を登録しました。", "success");

      onClose();
    } catch (e) {
      showSnackbar("エラーが発生しました", "error");
    } finally {
      setBackdrop(false);
    }
  };

  return (
    <Dialog open={open}>
      <DialogContent className="text-base">
        <span>工事期間を入力してください。</span>

        <Box className="flex items-center mt-2">
          <Box className="flex justify-center items-center font-bold rounded-md text-white text-xs w-8 h-6 mr-1 bg-mediumGray">任意</Box>

          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
            <DatePicker label="工事開始日" slotProps={{ textField: { size: "small" } }} onChange={handleChangeStartDate} />
          </LocalizationProvider>

          <p className="mx-2">〜</p>

          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
            <DatePicker label="工事終了日" slotProps={{ textField: { size: "small" } }} onChange={handleChangeEndDate} />
          </LocalizationProvider>
        </Box>
      </DialogContent>

      <DialogActions className="justify-around">
        <Button variant="outlined" className="bg-white font-bold text-darkBlueGray border-2 border-mediumGray" onClick={onClose}>
          キャンセル
        </Button>
        <Button variant="contained" className="bg-remodela-green font-bold" onClick={handleClickSave}>
          登録
        </Button>
      </DialogActions>
    </Dialog>
  );
};
